import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Stack from "react-bootstrap/Stack";
import ModuleSection from '@components/modules/moduleWrapper';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { StarberryIcons } from "@components/icons/index";
import ButtonLink from '@components/ButtonLink';
import Routes from '@components/routes'
import { ContactModal } from '@components/modal/modals';
import { ModalConsumer } from '@components/modal/ModalContext';
import { onlyNumbers, uaenumberformat } from '@components/utils/CleanedHtml';
import { cleanP } from '@components/utils/CleanedHtml';

import { delayItemsFromBottom } from '@components/utils/animations';

import './offices.scss';



const OfficeCard = ({ office, custom }) => {
    const {
        Address,
        WhatsappNumber,
        Title,
        Slug,
        PhoneNumber,
        Enquire,
        CardImg,
        imagetransforms
    } = office

    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.CardImg_Transforms) {
        processedImages = imagetransforms?.CardImg_Transforms;
    }

    return(
        <Col
            variants={delayItemsFromBottom}
            custom={custom}
        >
            <div
                className="office-card"
            >
                <ButtonLink manualLink={`${Routes.ContactUsPage}/${Slug}`} variant="none" className="office-card-image_wrapper">
                    {CardImg?.url ?
                        // <ImageTransform
                        //     imagesources={CardImg?.url}
                        //     renderer="srcSet"
                        //     imagename="offices.CardImg.image"
                        //     attr={{ alt: CardImg?.alternativeText ? CardImg.alternativeText : cleanP(Title), className: "office-card-image" }}
                        //     imagetransformresult={processedImages}
                        //     id={office.id}
                        // />
                        <img src={CardImg?.url} alt={CardImg?.alternativeText ? CardImg.alternativeText : cleanP(Title)} className={"office-card-image"} />
                    :
                        <StaticImage
                            src="../../images/no-image.png"
                            width={440}
                            height={280}
                            formats={["auto", "webp"]}
                            alt=""
                            className="office-card-image"
                        />
                    }
                </ButtonLink>
                <Stack direction="vertical" className="gap-8 p-24 p-md-40 p-lg-32 office-card-text">
                    {Title &&
                        <ModuleTitle
                            as="h3"
                            title={Title}
                            className="office-card__title"
                        />
                    }
                    {Address &&
                        <ModuleText
                            text={Address}
                            className="office-card__text mb-8"
                        />
                    }
                    {WhatsappNumber &&
                        <Stack direction="horizontal" className="gap-12 contact-container-link btn-icon-whatsApp">
                            <StarberryIcons iconName="whatsAppIcon" className="whatsapp-icon" />
                            <a href={`https://wa.me/${onlyNumbers(WhatsappNumber)}`} className="contact-link">{uaenumberformat(WhatsappNumber)}</a>
                        </Stack>
                    }
                    {PhoneNumber &&
                        <Stack direction="horizontal" className="gap-12 contact-container-link">
                            <StarberryIcons iconName="phoneIcon" className="phone-icon" />
                            <a href={`tel:${uaenumberformat(PhoneNumber)}`} className="contact-link">{uaenumberformat(PhoneNumber)}</a>
                        </Stack>
                    }
                    {Enquire &&
                        <Stack direction="horizontal" className="gap-12 contact-container-link">
                            <StarberryIcons iconName="emailIcon" className="mailto-icon" />
                            <ModalConsumer>
                                {({ showModal }) => (
                                    <Button variant="none" className="contact-link" onClick={() => showModal(ContactModal, { show: true })}>{Enquire}</Button>
                                )}
                            </ModalConsumer>
                        </Stack>
                    }
                    {Slug &&
                        <ButtonLink manualLink={`${Routes.ContactUsPage}/${Slug}`} variant="link-primary-underlined" btnClass="mt-8 mt-md-16 align-self-start office-link">View Office Details</ButtonLink>
                    }
                </Stack>
            </div>
        </Col>
    )
}

const Offices = ({ className }) => {
    const offices = useStaticQuery(graphql`
        query {
            glstrapi {
                offices(publicationState: LIVE, sort: "Order:ASC") {
                    Address
                    id
                    WhatsappNumber
                    Title
                    Slug
                    PhoneNumber
                    MapLong
                    MapLat
                    Enquire
                    Order
                    imagetransforms
                    CardImg {
                        alternativeText
                        url
                    }
                }
            }
        }
    `)

    return(
        <ModuleSection sectionClass={`module office-cards${className ? ' ' + className : ''}`}>
            <Row className={`row row-cols-1 row-cols-xl-3 gy-24 gy-md-40 gy-xl-0 gx-lg-40`}>
                {offices?.glstrapi?.offices?.map((office, i) => {
                    return(
                        <OfficeCard
                            key={office?.id}
                            office={office}
                            custom={i}
                        />
                    )
                })}
            </Row>
        </ModuleSection>
    )
}

export default Offices